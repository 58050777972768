import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Replace 'https://example.com' with the URL you want to redirect to
    window.location.href = 'https://onlyfans.com/jazmin.dior4';
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
}

export default App;
